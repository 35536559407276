import React from "react"
import "./form.css"
import { Link } from "gatsby"
import $ from "jquery"
// import Joi from "joi-browser"


export default class SignupForm extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    specialty: "",
    city: "",
    state: "",
    zipCode: "",
    npiNumber: "",
    licenseNumber: "",
    licenseState: "",
    validateFName: true,
    validateLName: true,
    validateEmail: true,
    validatePhone: true,
    validateSpecialty: true,
    validateCity: true,
    validateState: true,
    validateAddress: true,
    validateZipCode: true,
    validateNPI: true,
    validateLicense: true,
    validateLicenseState: true,
    hcp: false,
    validateHCP: true,
    valid: true,
    reps: false,
    account: { firstname: "", lastname: ""},
    error: {}
  }

  // schema = {
  //   firstname: Joi.string().required(),
  //   lastname: Joi.string().required()
  // }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  toggleReps = event =>{
    this.state.reps? this.setState({reps:false}) : this.setState({reps: true})
  }

  stateArray = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ]

  toggleValidation = event =>{
    this.state.hcp ? this.setState({hcp:false}) :  this.setState({hcp: true})
  }

  handleSelectChange = event => {
    let {name, value} = event.target;
    this.setState({
      [name]: value
    })
  }

  // method to check if the input is a valid name
  validateName = (name, event, errorDiv) => {
    let errorText = document.querySelector(`#${errorDiv}`);
    if(name.length < 2){
      if(errorDiv === "FNameError"){
        errorText.innerHTML = "Please enter a first name"
      }else{
        errorText.innerHTML = "Please enter a last name"
      }
      return false;
      event.preventDefault()

    }
    else if( (/[0-9!@#\$%^*)(+=_?\[\]{}<>|]+|http|www|\.com|\.edu|\.tech|\.net|\.gov/).test(name) ) {
      errorText.innerHTML = `Special characters like [!, @, #, $, ^] are not allowed. Please enter a valid name using letters and/or the following special characters: , ., \, /, &`
      return false;
      event.preventDefault()

    }
    // console.log("vnamefail")
    return true
  }

  validateEmail = (event) => {
    let errorText = document.querySelector('#emailError')
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(this.state.email))
    {
      return (true)
    }
    else if (!(/^.*@*(\.\w{2,5})+$/).test(this.state.email))
    {
      errorText.innerHTML = 'This email address is missing a top-level domain (such as ".com")'
    }
    else if(!(this.state.email).includes('@')){
      errorText.innerHTML = "This email address is missing `@`."
    }
    else{
      errorText.innerHTML = "Please enter a valid email"
    }
    // console.log("vemailfail")
    event.preventDefault()
    return (false)
  }

  validatePhone = () => {
    var phoneno = /^\d{10}$/;
    if(this.state.phoneNumber.match(phoneno)){
            return true;
          }
        else{
            return false;
          }
        }


  handleSubmit = (event) => {

    this.setState({validateFName: this.validateName(this.state.firstName, event, "FNameError")});
    this.setState({validateLName: this.validateName(this.state.lastName, event, "LNameError")});
    
    this.setState({validateEmail: this.validateEmail(event)}) 
    this.validatePhone() ? this.setState({validatePhone: true}) : this.setState({validatePhone:false},event.preventDefault(),console.log("vphonefail"))
    this.state.specialty.length > 0 ? this.setState({validateSpecialty: true}) : this.setState({validateSpecialty: false},event.preventDefault(),console.log("vspecialtyfail"))
    this.state.city.length >= 2 ? this.setState({validateCity:true}) : this.setState({validateCity: false},event.preventDefault(),console.log("vcityfail"))
    this.state.address.length  >= 2 ? this.setState({validateAddress:true}) : this.setState({validateAddress: false},event.preventDefault(),console.log("vaddressfail"))
    this.state.state.length  === 2 ? this.setState({validateState:true}) : this.setState({validateState: false},event.preventDefault(),console.log("vstatefail"))
    this.state.zipCode.length  === 5 || this.state.zipCode.length  === 9 ? this.setState({validateZipCode:true}) : this.setState({validateZipCode: false},event.preventDefault(),console.log("vzipfail"))
    this.state.npiNumber.length  === 10 ? this.setState({validateNPI:true}) : this.setState({validateNPI: false},event.preventDefault(),console.log("vnpifail"))
    this.state.licenseNumber.length  >= 2 ? this.setState({validateLicense:true}) : this.setState({validateLicense: false},event.preventDefault(),console.log("vlicensefail"))
    this.state.licenseState.length  >= 2 ? this.setState({validateLicenseState:true}) : this.setState({validateLicenseState: false},event.preventDefault(),console.log("vlicensestatefail"))
    if(this.state.hcp === true){
    this.setState({validateHCP:true})
    this.submitSignUpForm(
      function (err, res) {
      if (err) {
        // console.log(err);
      } else {
          // console.log(res);
      }
      }
    )
    }
    else{
      // console.log("vhcpfail")
      this.setState({validateHCP:false},event.preventDefault())
    }
    // const result = Joi.validate(this.state.account, this.schema, {abortEarly: false});
    // if(!result.error) return null;

    // const errors = {};
    // for (let item of result.error.details)
    //   errors[item.path[0]] = item.message;
    //   return errors;
  }

  submitSignUpForm = (callback) => {
     var payload = {
       firstName: this.state.firstName,
       lastName: this.state.lastName,
       email: this.state.email,
       phone: this.state.phoneNumber,
       address: this.state.address ,
       city: this.state.city,
       state: this.state.state,
       zip: this.state.zipCode,
       specialty: this.state.specialty,
       npi: this.state.npiNumber,
       licenseNumber: this.state.licenseNumber,
       licenseState: this.state.licenseState,
       is_requesting_rep_visit: this.state.reps,
       is_requesting_samples: true,
       is_opting_in: this.state.hcp,
       source:  "3",
     }

     $.ajax({
       url: "/phpSide/",
       data: payload,
       type: "POST",
       crossDomain: true,
       complete: function (resp) {
         // console.log('complete')
         callback(null, resp);
       },
       success: function(resp){
         /*if(resp.status == 200) {
            console.log("form submitted");
           console.log(resp)
         }
         else if (resp.status == 500) {
           console.log('err')
           console.log(resp)
         }
         else{
           console.log("else-triggered")
           console.log(resp)
         }*/
       },
       error: function(resp) {
         // console.log(resp)
         if(resp.status == 200) {
           // console.log("form submitted");
           callback(null, resp);
         }
         else if (resp.status == 500) {
           // console.log('err')
           callback(resp)
         }
       }
     })
   }

  render() {
    return (
      <div className="endpoints">
          <div className="signup">
          {/* <h1>Request rep visit</h1> */}

          <h2 className="fw-300">Enter your information to receive VASCEPA samples</h2>

          <em>
            <span>*Indicates required field</span>
          </em>

          <form className="rep-request-form">
            <div className="two-sections m-y-20">
              <label>
                *First name

                <br></br>
                <input
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleInputChange}
                />
              {this.state.validateFName ? <div className="validation" id="FNameError"></div> : <div className="validation" id="FNameError"> Please enter a first name </div>}
              
              </label>

              <label className="m-l-40">
                *Last name

                <br></br>
                <input
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleInputChange}
                />
              {this.state.validateLName? <div className="validation" id="LNameError"></div> : <div className="validation" id="LNameError"> Please enter a last name </div>}
              </label>
            </div>
            <div className="two-sections m-y-20">
              <label>
                *Email

                <br></br>
                <input
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              <div className="validation" id="emailError"></div>
              </label>
              <label className="m-l-40">
                *Phone number

                <br></br>
                <input
                  type="text"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.handleInputChange}
                />
              {this.state.validatePhone ? "" : <div className="validation"> Please enter a valid phone number </div>}
              </label>
            </div>
            <label className="full-width-input">
              *Street address

              <br />
              <input
                type="text"
                name="address"
                value={this.state.address}
                onChange={this.handleInputChange}
              />
            {this.state.validateAddress ? "" :  <div className="validation"> Please enter a street address </div>}
            </label>
            <div className="two-sections m-y-20">
              <label>
                *City

                <br />
                <input
                  type="text"
                  name="city"
                  value={this.state.city}
                  onChange={this.handleInputChange}
                />
              {this.state.validateCity ? "" :  <div className="validation"> Please enter a city </div>}
              </label>
              <label className="m-l-40">
                *State

                <br />
                  <select className="half-width-select" name="state" onChange={this.handleSelectChange}>
                    <option value="">State</option>
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AZ">AZ</option>
                    <option value="AR">AR</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="DC">DC</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="IA">IA</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="ME">ME</option>
                    <option value="MD">MD</option>
                    <option value="MA">MA</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MS">MS</option>
                    <option value="MO">MO</option>
                    <option value="MT">MT</option>
                    <option value="NE">NE</option>
                    <option value="NV">NV</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NY">NY</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="WV">WV</option>
                    <option value="WI">WI</option>
                    <option value="WY">WY</option>
                  </select>
                  {this.state.validateState ? "" : <div className="validation"> Please select a state from the dropdown above </div>}
              </label>
            </div>
            <div className="m-y-20">
            <label className="">
              *ZIP code

              <br></br>
              <input
                type="text"
                name="zipCode"
                value={this.state.zipCode}
                onChange={this.handleInputChange}
              />
            {this.state.validateZipCode ? "" : <div className="validation"> Please enter a valid 5- or 9-digit ZIP code</div>}
            </label>
            </div>



            <label>
              *Specialty

              <br></br>
                <select name="specialty" onChange={this.handleSelectChange} >
                <option value="">
                  {" "}
                  Select one
                </option>
                <option value="cardiology">Cardiology</option>
                <option value="endocrinology">Endocrinology</option>
                <option value="familyPractice">Family Practice</option>
                <option value="generalPractice">General Practice</option>
                <option value="internalMedicine">Internal Medicine</option>
                <option value="nursePractitioner">Nurse Practitioner</option>
                <option value="pharmacist">Pharmacist</option>
                <option value="physicianAssistant">Physician Assistant</option>
                <option value="other">Other</option>
              </select>
              {this.state.validateSpecialty ? "" : <div className="validation"> Please select a specialty from the dropdown above </div>}
            </label>


            <div className="two-sections m-y-20">
              <label>
                *NPI number

                <br></br>
                <input
                  type="text"
                  name="npiNumber"
                  value={this.state.npiNumber}
                  onChange={this.handleInputChange}
                />
              {this.state.validateNPI? "" : <div className="validation"> Please enter a valid 10-digit NPI number </div>}
              </label>
              <label className="m-l-40">
                *License number

                <br></br>
                <input
                  type="text"
                  name="licenseNumber"
                  value={this.state.licenseNumber}
                  onChange={this.handleInputChange}
                />
              {this.state.validateLicense? "" : <div className="validation"> Please enter a license number </div>}
              </label>
            </div>

          	<label className="">
              *State of license

              <br></br>
              <select
                  name="stateOfLicense"
                  name="licenseState"
                  value={this.state.licenseState}
                  onChange={this.handleInputChange}
                  >
                <option value="">State</option>
                {this.stateArray.map(item => <option key={item} value={item}>{item}</option>)}
                </select>
                  {this.state.validateLicenseState ? "" :  <div className="validation"> Please select a state from the dropdown above</div>}
            </label>

            {/*<h2>Additional request</h2>*/}
            <div style={{display:"none"}}>
              <label style={{marginBottom:"0px"}} className="label-req">
               <br/>
                <input className="hcpCheckbox" onClick={this.toggleReps} type="checkbox" />
                <span className="p-l-5 m-p-l-16">I would like to request a rep visit</span>
              </label>
            </div>

            <div style={{maxWidth:"550px"}}>
              <label className="label-req">
               <br/>
                <input className="hcpCheckbox" onClick={this.toggleValidation} style={{position:"absolute", marginTop:"6px"}} type="checkbox" />
                <span style={{fontWeight:"300",paddingLeft:"18px",display:"block"}} className="p-l-5 m-p-l-30">I certify that I am a Healthcare Professional and want to receive information from Amarin, and third parties working on behalf of Amarin. I agree that Amarin may use the information I provide to send me health-related materials, as well as information on Amarin services, including marketing programs. I understand that I can opt out at any time by clicking the “Unsubscribe” at the bottom of all emails received.
                </span>
                  {this.state.validateHCP ? "" :  <div style={{fontWeight:"300",paddingLeft:"0px"}} className="validation">Please certify your understanding of the above information</div>}
              </label>
            </div>
            <p style={{paddingLeft:"0px"}}>
              Your registration is subject to Amarin's{" "}
              <a
                href="https://amarincorp.com/privacy_policy.html"
                target="_blank">
                Privacy Policy
              </a>
              .
            </p>
            <Link onClick={this.handleSubmit} style={{textDecoration:"none"}} to="register-thank-you"><button type="submit" value="Submit" className="homeBtn mobile-align-center">Submit</button></Link>
          </form>
        </div>
        <div class="dosing-callout"></div>
      </div>

    )
  }
}
