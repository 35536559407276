import Layout from "../components/Layout"
import SignupForm from "../components/form/signupform"
import "../components/form/form.css"
import Hero from '../components/hero/Hero'
import React, { Component } from "react"
import ISI from '../components/isi/ISI'
import { Waypoint } from 'react-waypoint';
import Footer from '../components/footer/Footer'
import SEO from "../components/seo"

import Loader from "../components/loader"



export default class Signup extends Component {


    render() {
        return (
            <>
            <Loader>
            <Layout>
              <SEO
                title="Register for Samples"
                keywords={[""]}
                description="Register now for samples and to keep informed about VASCEPA."/>
                <Hero bckgrdImage={'landingimage'}>
                    <h1 className="headline">Register now for samples and to stay informed</h1>
                </Hero>

                <div className="vascepa-register">
                    <div className="two-sections" >
                        <div className="right-options">
                            <SignupForm/>
                        </div>
                    </div>
                </div>
            </Layout>
            <ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
            <Waypoint
             onEnter={this.handleFooterEnter}
             onLeave={this.handleFooterLeave}/>
            <Footer />
            </Loader>
            </>
        )
    }
}
